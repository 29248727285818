<template>
  <v-layout
    :style="{
      position: isPc ? 'relative' : undefined,
      flexDirection: isPc ? 'column' : 'row',
    }"
  >
    <JwGnbHeader v-if="isPc" />
    <v-navigation-drawer
      v-model="sideNavWrap.show"
      name="left"
      touchless
      :class="{ 'scroll-bar': isPc }"
      :absolute="isPc"
      :style="{
        width: !isMobile ? '375px' : '100%',
        // height: isPc ? 'auto' : 'calc(100vh - 75px)',
        // bottom: isPc ? '155px' : 'var(--v-layout-bottom)',
        height: isPc ? 'auto' : '100%',
        bottom: isPc ? '155px' : '0',
        paddingBottom: isPc ? '0' : '75px',
        left: isPc ? '50%' : undefined,
        marginLeft: isPc ? '-450px' : undefined,
        paddingTop: isPc ? '50px' : undefined,
      }"
    >
      <v-toolbar color="background" absolute class="jw-app-header jw-app-header--more px-5">
        <v-toolbar-title> 더보기 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="24" :class="{ new: alarmNotiCount.alarm }" @click="router.push('/more/alarm')">
          <v-icon size="18">jwIcons:svg/bell</v-icon>
        </v-btn>
        <v-btn size="24" class="ml-4" :class="{ new: alarmNotiCount.noti }" @click="router.push('/more/notice')">
          <v-icon size="20">jwIcons:svg/bullhorn</v-icon>
        </v-btn>
        <v-btn size="24" class="ml-4 me-0" @click="router.push('/more/setting')">
          <v-icon size="20">jwIcons:svg/gear</v-icon>
        </v-btn>
      </v-toolbar>
      <div style="padding-top: 55px !important">
        <swiper v-bind="defaultSwiperOption" class="jw-swiper pt-4 pb-7">
          <swiper-slide class="full">
            <div class="jw-more-dashboard jw-more-dashboard--wrap">
              <div class="jw-more-dashboard__header">
                <div class="title">예수길벗교회<small>(이호훈목사)</small></div>
              </div>
              <div class="jw-more-dashboard__body">
                <div class="body__thumbs">
                  <div class="thumbs__item">
                    <VImg src="~/assets/images/sample_moretop_01.png" width="100%" height="120" cover>
                      <template v-slot:placeholder>
                        <div class="thumbs-placeholder">
                          <p>준비중입니다.</p>
                        </div>
                      </template>
                    </VImg>
                    <span class="up">UP</span>
                    <div class="item-cover">
                      <div class="item-cover__title">금주 설교</div>
                      <div class="progress">
                        <span class="bar" style="width: 60%"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body__thumbs">
                  <div class="thumbs__item">
                    <!-- <VImg src="~/assets/images/sample_moretop_02.png" width="100%" height="120" cover> </VImg> -->
                    <VImg width="100%" height="120" cover>
                      <template v-slot:placeholder>
                        <div class="thumbs-placeholder">
                          <p>준비중입니다.</p>
                        </div>
                      </template>
                    </VImg>
                    <!-- <span class="up">UP</span> -->
                    <div class="item-cover">
                      <div class="item-cover__title">주보</div>
                    </div>
                  </div>
                </div>
                <div class="body__desc">
                  <h3 class="mb-3">
                    설교본문<br />
                    <small>요 10:11~11:32</small>
                  </h3>
                  <VBtn variant="flat" height="30" rounded color="#0056BC" class="px-3" @click="router.push('/more/offering')">온라인 헌금</VBtn>
                  <!-- <p class="mt-2">
                    설교말씀 / 주보등의 정보를 제공하고 있지 않습니다.
                    <v-btn variant="text" size="small" color="#FFD600" class="text-decoration-underline">요청하기</v-btn>
                  </p> -->
                </div>
              </div>
              <div class="jw-more-dashboard__footer">
                <VBtn variant="text" rounded="0" height="40" class="px-2">홈페이지</VBtn>
                <VBtn variant="text" rounded="0" height="40" class="px-2">유튜브</VBtn>
                <VBtn variant="text" rounded="0" height="40" class="px-2">새가족등록</VBtn>
                <VBtn variant="text" rounded="0" height="40" class="px-2">인스타그램</VBtn>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="full">
            <div class="jw-more-dashboard jw-more-dashboard--wrap">
              <div class="jw-more-dashboard__header">
                <div class="title">목양교회<small>(김태곤목사)</small></div>
              </div>
              <div class="jw-more-dashboard__body">
                <div class="body__thumbs">
                  <div class="thumbs__item">
                    <!-- <VImg src="~/assets/images/sample_moretop_01.png" width="100%" height="120" cover> -->
                    <VImg width="100%" height="120" cover>
                      <template v-slot:placeholder>
                        <div class="thumbs-placeholder">
                          <p>준비중입니다.</p>
                        </div>
                      </template>
                    </VImg>
                    <span class="up">UP</span>
                    <div class="item-cover">
                      <div class="item-cover__title">금주 설교</div>
                      <div class="progress">
                        <span class="bar" style="width: 60%"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="body__thumbs">
                  <div class="thumbs__item">
                    <!-- <VImg src="~/assets/images/sample_moretop_02.png" width="100%" height="120" cover> </VImg> -->
                    <VImg width="100%" height="120" cover>
                      <template v-slot:placeholder>
                        <div class="thumbs-placeholder">
                          <p>준비중입니다.</p>
                        </div>
                      </template>
                    </VImg>
                    <!-- <span class="up">UP</span> -->
                    <div class="item-cover">
                      <div class="item-cover__title">주보</div>
                    </div>
                  </div>
                </div>
                <div class="body__desc">
                  <!-- <h3 class="mb-3">
                    설교본문<br />
                    <small>요 10:11~11:32</small>
                  </h3> -->
                  <VBtn variant="flat" height="30" rounded color="#0056BC" class="px-3" @click="router.push('/more/offering')">온라인 헌금</VBtn>
                  <p class="mt-2">
                    설교말씀 / 주보등의 정보를 제공하고 있지 않습니다.
                    <v-btn variant="text" size="small" color="#FFD600" class="text-decoration-underline">요청하기</v-btn>
                  </p>
                </div>
              </div>
              <div class="jw-more-dashboard__footer">
                <VBtn variant="text" rounded="0" height="40" class="px-2">홈페이지</VBtn>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="full">
            <div class="jw-more-dashboard jw-more-dashboard--wrap">
              <div class="jw-more-dashboard__header">
                <div class="title">우리 교회 소식 / 주보 / 설교 등록</div>
                <v-spacer />
                <v-btn variant="text" size="small" color="white">
                  <v-icon size="16" class="mr-02">jwIcons:svg/circled-question</v-icon>
                  알아보기
                </v-btn>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <v-container :fluid="!isPc" class="px-5 py-0 mt-n2">
        <v-list class="jw-more--links">
          <v-list-item v-for="(item, index) in moreLinks" :key="index" class="px-0" :class="{ new: item.isNew }">
            <VBtn variant="text" :href="item.url" :target="item.target" @click="onClickMoreLinks(item.target)">
              <VIcon size="36">{{ item.image }}</VIcon>
              <span class="text">{{ item.text }}</span>
            </VBtn>
          </v-list-item>
        </v-list>
      </v-container>

      <!-- 배너 시작 -->
      <div class="mb-5 mt-5">
        <MoreBanner />
      </div>
      <!--/ 배너 끝 -->

      <v-container class="px-5 py-0 mb-5">
        <v-list class="jw-link-border mt-5">
          <v-list-item class="px-2" v-for="(item, index) in links" :key="index" :href="item.url" :target="item.target">
            <span class="text">{{ item.text }}</span>
            <v-spacer></v-spacer>
            <VIcon size="24" color="#000000">jwIcons:svg/chevron-right-gray-thin</VIcon>
          </v-list-item>
        </v-list>

        <VBtn icon color="transparent" class="jw-more-question">
          <img src="~/assets/images/more-question.svg" />
        </VBtn>
      </v-container>
    </v-navigation-drawer>
    <VMain
      name="main"
      :style="{
        '--v-layout-top': !isPc ? 0 : '50px',
        '--v-layout-left': !isPc && isTablet && !isMobile ? '375px' : 0,
        '--v-layout-bottom': isMobile ? '75px' : 0,
        backgroundColor: isPc ? '#FFFCF8' : undefined,
        overflow: sideNavWrap.show && isMobile ? hidden : undefined,
      }"
    >
      <v-container
        v-resize="onResize"
        :fluid="!isPc"
        class="jw-more--right"
        :style="{ padding: 0, paddingLeft: isPc ? '375px' : 0, minHeight: isPc ? `calc(100vh - 210px)` : undefined, paddingTop: isPc ? '50px' : 0 }"
      >
        <div ref="jwMainContainer">
          <!-- {{ `isPc:${isPc}, isTablet:${isTablet}, isMobile:${isMobile}` }} -->
          <!-- <slot /> -->
          <ClientOnly>
            <NuxtPage />
          </ClientOnly>
        </div>
      </v-container>
    </VMain>
    <JwFooter v-if="showMainFooter" />
    <JwBottomNav
      v-if="!isPc"
      :style="{
        width: isMobile ? '100%' : '375px',
        left: isMobile ? 0 : undefined,
        paddingLeft: isMobile ? 0 : undefined,
        zIndex: 1007,
        borderRight: !isMobile ? '1px solid rgba(var(--v-border-color), var(--v-border-opacity))' : undefined,
      }"
    />
  </v-layout>
</template>
<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
// import MoreBanner from "~/components/more/MoreBanner.vue";

import JwGnbHeader from "@/components/common/JwGnbHeader.vue";
import JwBottomNav from "@/components/common/JwBottomNav.vue";
import JwFooter from "@/components/common/JwFooter.vue";

import { useDisplay } from "vuetify";
import { useMoreStore } from "~/store/more";
import { storeToRefs } from "pinia";
import { getMyGroupAccountAPI, getMyGroupDashboardAPI } from "~/store/api/more";

const router = useRouter();
const route = useRoute();
const { smAndDown, xlAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);
const isMobile = computed(() => smAndDown.value);
const isTablet = computed(() => !isPc.value && !isMobile.value);

const jwMainContainer = ref(null);

const moreStore = useMoreStore();

const defaultSwiperOption = reactive({
  slidesPerView: "auto",
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  spaceBetween: 10,
});

const sideNavWrap = reactive({
  width: "375px",
  height: "auto",
  show: true,
});

onBeforeMount(() => {
  getAlarmNotiCountAction();
  getBannerAction();
});

onMounted(() => {
  onResize();
});

onUpdated(() => {
  onResize();
});

watch(() => route.path, onResize);

async function onResize() {
  await nextTick();
  sideNavWrap.width = !isMobile.value ? "375px" : "100%";
  sideNavWrap.height = jwMainContainer.value?.getBoundingClientRect().height + 50;
  sideNavWrap.show = !isMobile.value || route.path.endsWith("/more");
}

const showMainFooter = computed(() => {
  // if (["today-section-id", "shareDetail"].includes(route.name)) {
  //   return false;
  // } else {
  return isPc.value ? true : false;
  // }
});

/*
 * 새 알림/공지
 */
const { alarmNotiCount } = storeToRefs(moreStore);

async function getAlarmNotiCountAction() {
  await moreStore.getAlarmNotiCount();
}

watch(
  (v1 = route.meta.isAlarmPage, v2 = route.meta.isNoticePage) => {
    if(v1 || v2) getAlarmNotiCountAction();
  },
  { deep: true, immediate: true }
);

/*
 * 배너
 */
async function getBannerAction() {
  await moreStore.getBannerList();
}

/*
 * 링크
 */
const moreLinks = reactive([
  {
    image: "jwIcons:svg/more-edu",
    text: "동행훈련",
    url: "https://365.jwj.kr",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-sermon",
    text: "유기성목사",
    url: "https://www.youtube.com/@PastorYooKiSung",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-wjm",
    text: "위지엠",
    url: "https://www.youtube.com/channel/UCDwDZlwDblLU7v7Jq_px89w",
    target: "_blank",
  },
  {
    image: "jwIcons:svg/more-sponsor",
    text: "후원",
    url: "",
    target: "sponsor"
  },
  {
    image: "jwIcons:svg/more-fellow",
    text: "동역",
    url: "",
    target: "fellow"
  },
]);

const links = reactive([
  {
    text: "jwj.kr 바로가기",
    url: "https://jwj.kr",
    target: "_self"
  },
  {
    text: "예수동행일기 안내",
    url: "https://wjm.kr/with-jesus-journal",
    target: "_blank"
  },
  {
    text: "자주 묻는 질문",
    url: "https://withjesusministry.notion.site/WJM-7c859d3703ae4213b610f6565420b7f6",
    target: "_blank"
  },
]);

function onClickMoreLinks(target) {
  if(target != "_blank") router.push(`/more/${target}`);
}
</script>
